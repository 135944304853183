<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="800"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div style="overflow: auto; max-height: 600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        v-if="dialogVisible"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="标题：" prop="title">
          <el-input v-model="ruleForm.title" maxlength="48"></el-input>
        </el-form-item>
        <el-form-item label="所属分类：" prop="articleType">
          <el-select
            v-model="ruleForm.articleType"
            style="width: 100%"
            @change="handleChangeSelect"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.articleTypeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缩略图：" prop="thumb">
          <div style="display: flex; align-items: end">
            <el-upload
              action="#"
              list-type="picture-card"
              ref="picture-card"
              :limit="1"
              :on-change="handleBeforeUpload"
              :show-file-list="false"
              :auto-upload="false"
              accept=".jpg, .png, jpeg, .gif"
            >
              <img v-if="ruleForm.thumb" :src="ruleForm.thumb" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div style="font-size: 14px; color: #cccccc">
              （尺寸：130px*130px）
            </div>
          </div>
        </el-form-item>
        <el-form-item label="是否显示：" prop="enable">
          <el-radio v-model="ruleForm.enable" :label="true">是</el-radio>
          <el-radio v-model="ruleForm.enable" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="阅读量：" prop="pageView">
          <el-input
            v-model="ruleForm.pageView"
            maxlength="48"
            @input="
              input_num($event, ruleForm, 'pageView', 9999999999999999999999, 0)
            "
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="推荐位置：" prop="position">
          <el-select
            v-model="ruleForm.position"
            style="width: 100%"
            @change="handleChangeSelect2"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeList"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input
            v-model="ruleForm.sort"
            maxlength="48"
            min="1"
            @input="
              input_num($event, ruleForm, 'sort', 9999999999999999999999, 0)
            "
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容：" prop="articleContent">
          <!-- <froala ref="editor"
          v-model="ruleForm.articleContent"
          style="width: 500px"
          :tag="'textarea'"
          :content="ruleForm.articleContent"
          :config="setting" /> -->
          <div class="vue-quill-editor">
            <quill-editor
              ref="mwQuillEditor"
              v-model="ruleForm.articleContent"
              :options="editorConfig"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <el-button @click="resetForm('ruleForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->

    <!-- <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
   </el-dialog> -->
  </el-dialog>
</template>

<script>
import { Quill } from "vue-quill-editor";
import { articleSave, articleTypeList, articleInfo } from "@/api/SystemData";
import { dictionaryBatch } from "@/api/policy.js";
import { OSSDirectPass } from "@/api/oss.js";
import { uploadFile } from "@/utils/index";
import Video from "@/utils/quill/video.js";
Quill.register(Video, true);
// 设置字体大小
const fontSizeStyle = Quill.import("attributors/style/size"); // 引入这个后会把样式写在style上
fontSizeStyle.whitelist = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "36px",
];
Quill.register(fontSizeStyle, true);
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.articleId) {
          articleInfo({
            aid: this.editData.articleId,
          }).then((res) => {
            res.data.articleId = this.editData.articleId;
            this.ruleForm = JSON.parse(JSON.stringify(res.data));
          });
          //  this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          //  this.ruleForm.enable == 'true' ? this.ruleForm.enable = true : this.ruleForm.enable = false
        } else if (!v) {
          this.ruleForm = {
            title: "",
            articleType: "",
            articleTypeName: "",
            thumb: "",
            enable: true,
            pageView: "",
            position: "",
            positionName: "",
            sort: 1,
            articleContent: "",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    const thats = this;
    return {
      ruleForm: {
        title: "",
        articleType: "",
        articleTypeName: "",
        thumb: "",
        enable: true,
        pageView: "",
        position: "",
        positionName: "",
        sort: 1,
        articleContent: "",
      },
      typeList: [],
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        articleType: [
          { required: true, message: "请选择所属分类", trigger: "change" },
        ],
        thumb: [{ required: true, message: "请选择缩略图", trigger: "change" }],
      },
      loading: true,
      options: [],
      updateThumb: {
        url: "",
        file: null,
      },
      setting: {
        placeholderText: "请在此输入",
        language: "zh_cn", //语言
        charCounterCount: true,
        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "subscript",
              "superscript",
              "fontFamily",
              "fontSize",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "inlineStyle",
              "clearFormatting",
            ],
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "paragraphStyle",
              "lineHeight",
              "outdent",
              "indent",
              "quote",
            ],
          },
          moreRich: {
            buttons: [
              "insertImage",
              "insertVideo",
              "emoticons",
              "fontAwesome",
              "specialCharacters",
              "embedly",
              "insertHR",
            ],
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "html",
              "spellChecker",
              "selectAll",
              "help",
            ],
            align: "right",
            buttonsVisible: 4,
          },
        },
        events: {
          "image.beforeUpload": function (response) {
            const that = this;
            // 文件base64string获取
            if (window.FileReader) {
              thats.updateFile(response[0], that.html);
              //   var reader = new FileReader();
              //   reader.readAsDataURL(response[0])
              // 监听文件读取结束后事件
              //   reader.onloadend = function(img) {
              //  that.html.insert(`<img src="${img.target.result}" style="width: 300px;" class="fr-fic fr-dib" />`, false)
              //   }
            }
            return false;
          },
          "video.beforeUpload": function (response) {
            const that = this;
            // 文件base64string获取
            if (window.FileReader) {
              thats.updateFile(response[0], that.html, "video");
              //   var reader = new FileReader();
              //   reader.readAsDataURL(response[0])
              // 监听文件读取结束后事件
              //   reader.onloadend = function(img) {
              //  that.html.insert(`<img src="${img.target.result}" style="width: 300px;" class="fr-fic fr-dib" />`, false)
              //   }
            }
            return false;
          },
        },
      },
      // 简单配置
      editorConfig: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{ align: [] }], // 对齐方式-----[{ align: [] }]
              [{ size: fontSizeStyle.whitelist }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              //   [{ font: fonts }], // 字体种类-----[{ font: [] }]
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
              [{ direction: "ltl" }], // 文本方向-----[{'direction': 'rtl'}]
              [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
              [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
              ["clean"], // 清除文本格式-----['clean']
              ["link", "image", "video"], // 链接、图片、视频-----['link', 'image', 'video']
            ],
            handlers: {
              image: this.handleImgUpload,
              video: this.handleVideoUpload,
            },
          },
        },
      },
      editor: null,
    };
  },
  async created() {
    this.initSearch();
  },
  methods: {
    ready(editor) {
      // 这里可以拿到ueditor的实例，比如editor.getContent()就可以拿到编辑器的html内容
      // 把获取到的editor实例绑定到数据editor中，这样用this.editor.getContent()就可以随地拿到编辑器的html内容
      this.editor = editor;
    },
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    initSearch() {
      dictionaryBatch({ codes: "recommendPosition" }).then((res) => {
        const { recommendPosition } = res.data;
        this.typeList = recommendPosition;
      });
      articleTypeList().then((res) => {
        this.options = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));

          if (this.updateThumb.url) {
            let url = await OSSDirectPass(this.updateThumb.file);
            data.thumb = url;
          }
          articleSave({ ...data }).then((res) => {
            this.handleClose();
            this.$message.success("保存成功！");
            this.$emit("changeList", 1);
          });
        } else {
          console.log("error submit!!");
          this.$message.error("保存失败！");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          title: "",
          articleType: "",
          articleTypeName: "",
          thumb: "",
          enable: true,
          pageView: "",
          position: "",
          positionName: "",
          sort: 1,
          articleContent: "",
        };
      }
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    handleBeforeUpload(file, fileList) {
      this.updateThumb.url = file.url;
      this.updateThumb.file = file;
      this.ruleForm.thumb = file.url;
      this.$refs["picture-card"].clearFiles();
    },
    handleChangeSelect(e) {
      let data = this.options.find((val) => val.id == e);
      this.ruleForm.articleTypeName = data.articleTypeName;
    },
    handleChangeSelect2(e) {
      if (e) {
        let data = this.typeList.find((val) => val.dictValue == e);
        this.ruleForm.positionKey = data.dictKey;
      } else {
        this.ruleForm.positionKey = "";
      }
    },
    async updateFile(e, html, type) {
      let url = await OSSDirectPass({
        name: e.name,
        raw: e,
      });
      if (type == "video") {
        console.log(url);
        html.insert(
          `<video controls src="${url}" style="width: 300px;" class="fr-fic fr-dib" />`,
          false
        );
      } else {
        html.insert(
          `<img src="${url}" style="width: 300px;" class="fr-fic fr-dib" />`,
          false
        );
      }
      // return url;
    },
    handleImgUpload(e) {
      const { quill } = this.$refs.mwQuillEditor;
      uploadFile({ accept: "image/*", fileSize: 10 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        const length = quill.getSelection(true).index;
        // 获取光标所在位置
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      });
    },
    handleVideoUpload() {
      const { quill } = this.$refs.mwQuillEditor;
      uploadFile({ accept: "video/*", fileSize: 100 }).then(async (res) => {
        let url = await OSSDirectPass({
          name: res.name,
          raw: res,
        });
        const length = quill.getSelection(true).index;
        // 获取光标所在位置
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "video", url);
        // 调整光标到最后
        quill.setSelection(length + 1);
      });
    },

    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和"."以外的字符
      e = e.replace(/[^\d.]/g, "");
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (decimal == 2) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      } else if (decimal == 3) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d).*$/, "$1$2.$3");
      } else if (decimal == 0) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.*$/, "$1$2.$3");
      } else {
        // 只能输入4个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3");
      }

      //如果有小数点，不能为类似 1.10的金额
      // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      // //如果有小数点，不能为类似 0.00的金额
      // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (e.indexOf(".") <= 0 && e != "") {
        tiem[key] = parseFloat(e);
      }
      if (e > max) {
        const aa = e + "";
        tiem[key] = parseFloat(aa.slice(0, e.length - 1));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
.avatar {
  width: 100%;
  max-height: 100%;
}
.demo-ruleForm {
  max-height: 500px;
  overflow: auto;
}
</style>
<style lang="scss">
a[href="https://froala.com/wysiwyg-editor"], a[href="https://www.froala.com/wysiwyg-editor?k=u"]
{
  display: none !important;
  position: absolute;
  top: -99999999px;
}
.vue-quill-editor {
  .quill-editor {
    line-height: normal;
    .ql-container.ql-snow {
      line-height: normal !important;
      height: 550px !important;
      font-size: 14px;
    }
    .ql-snow {
      max-height: 360px !important;
      .ql-tooltip[data-mode="link"]::before {
        content: "请输入链接地址:";
      }
      .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: "保存";
        padding-right: 0px;
      }
      .ql-tooltip[data-mode="video"]::before {
        content: "请输入视频地址:";
      }
      .ql-picker.ql-size {
        .ql-picker-label[data-value="12px"]::before,
        .ql-picker-item[data-value="12px"]::before {
          content: "12px";
        }
        .ql-picker-label[data-value="14px"]::before,
        .ql-picker-item[data-value="14px"]::before {
          content: "14px";
        }
        .ql-picker-label[data-value="16px"]::before,
        .ql-picker-item[data-value="16px"]::before {
          content: "16px";
        }
        .ql-picker-label[data-value="18px"]::before,
        .ql-picker-item[data-value="18px"]::before {
          content: "18px";
        }
        .ql-picker-label[data-value="20px"]::before,
        .ql-picker-item[data-value="20px"]::before {
          content: "20px";
        }
        .ql-picker-label[data-value="24px"]::before,
        .ql-picker-item[data-value="24px"]::before {
          content: "24px";
        }
        .ql-picker-label[data-value="28px"]::before,
        .ql-picker-item[data-value="28px"]::before {
          content: "28px";
        }
        .ql-picker-label[data-value="32px"]::before,
        .ql-picker-item[data-value="32px"]::before {
          content: "32px";
        }
        .ql-picker-label[data-value="36px"]::before,
        .ql-picker-item[data-value="36px"]::before {
          content: "36px";
        }
      }
      .ql-picker.ql-header {
        .ql-picker-label::before,
        .ql-picker-item::before {
          content: "文本";
        }
        .ql-picker-label[data-value="1"]::before,
        .ql-picker-item[data-value="1"]::before {
          content: "标题1";
        }
        .ql-picker-label[data-value="2"]::before,
        .ql-picker-item[data-value="2"]::before {
          content: "标题2";
        }
        .ql-picker-label[data-value="3"]::before,
        .ql-picker-item[data-value="3"]::before {
          content: "标题3";
        }
        .ql-picker-label[data-value="4"]::before,
        .ql-picker-item[data-value="4"]::before {
          content: "标题4";
        }
        .ql-picker-label[data-value="5"]::before,
        .ql-picker-item[data-value="5"]::before {
          content: "标题5";
        }
        .ql-picker-label[data-value="6"]::before,
        .ql-picker-item[data-value="6"]::before {
          content: "标题6";
        }
      }
      .ql-picker.ql-font {
        .ql-picker-label[data-value="SimSun"]::before,
        .ql-picker-item[data-value="SimSun"]::before {
          content: "宋体";
          font-family: "SimSun" !important;
        }
        .ql-picker-label[data-value="SimHei"]::before,
        .ql-picker-item[data-value="SimHei"]::before {
          content: "黑体";
          font-family: "SimHei";
        }
        .ql-picker-label[data-value="Microsoft-YaHei"]::before,
        .ql-picker-item[data-value="Microsoft-YaHei"]::before {
          content: "微软雅黑";
          font-family: "Microsoft YaHei";
        }
        .ql-picker-label[data-value="KaiTi"]::before,
        .ql-picker-item[data-value="KaiTi"]::before {
          content: "楷体";
          font-family: "KaiTi" !important;
        }
        .ql-picker-label[data-value="FangSong"]::before,
        .ql-picker-item[data-value="FangSong"]::before {
          content: "仿宋";
          font-family: "FangSong";
        }
      }
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-left {
      text-align: left;
    }
  }
}
</style>
